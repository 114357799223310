import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function ProductSkeleton({ loadMore }) {

    let skeleton = [];

    if (loadMore)
        for (let i = 0; i < 4; i++) {
            skeleton.push(

                <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center " key={`products-skeleton-${i}`}>
                    <div className="product-card p-1">
                        <Skeleton height={200} />
                        <div className="product-info">
                            <Skeleton height={20} count={3} className="p-2" />
                            <Skeleton height={40} className="mt-4" />
                        </div>
                    </div>
                </div>
            );
        }
    else
        for (let i = 0; i < 12; i++) {
            skeleton.push(

                <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center " key={`products-skeleton-${i}`}>
                    <div className="product-card p-1">
                        <Skeleton height={200} />
                        <div className="product-info">
                            <Skeleton height={20} count={3} className="p-2" />
                            <Skeleton height={40} className="mt-4" />
                        </div>
                    </div>
                </div>
            );
        }

    const SkeltonWithTheme = <SkeletonTheme highlightColor="#bababa">
        <div className="container-fluid">
            <div className="row g-3 py-4">
                {skeleton}
            </div>
        </div>
    </SkeletonTheme>

    return loadMore ? SkeltonWithTheme :
        <div className="page-content-wrapper">
            {SkeltonWithTheme}
        </div>
}

export default ProductSkeleton;
