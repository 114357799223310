import React, { useState } from 'react'

export default function ProductImageLoader({ serverImgPath, src }) {
    const [loaded, setLoaded] = useState(false);
    const publicUrl = process.env.PUBLIC_URL || window.location.origin;
    const loader = publicUrl + '/bg-img/chicken.gif';
    return (
        <>
            <img src={loader} alt="" style={loaded ? { display: 'none' } : {}} />
            <img
                style={loaded ? {} : { display: 'none' }}
                src={`${serverImgPath}${src}`}
                onLoad={() => setLoaded(true)}
                alt=""
            />
        </>
    );
}
