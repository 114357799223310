import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import ProductCountHeader from "./ProductCountHeader";
import Skeleton from "./Skeleton";
import { cartItemContext } from "../../context/cartItemContext";
import { serverContext } from "../../context/serverContext";
import ProductImageLoader from "../ProductImageLoader";
import InfiniteScroll from "react-infinite-scroll-component";

function ProductItem({
    productList,
    totalNumOfProducts,
    loadedPageNo,
    fetchMoreProducts
}) {
    const uuid = require("uuid");
    const { productsInCart, addProductsToCart, updateProductQuantityInCart } = useContext(cartItemContext);
    const { serverImagePath } = useContext(serverContext);
    const imagePath = serverImagePath + "/products/";

    const [selectedConfigs, setSelectedConfigs] = useState({});

    //handle product config
    const handleConfigSelection = (productId, value, price) => {

        setSelectedConfigs(prevSizes => ({
            ...prevSizes,
            [productId]: { "Value": value, "Price": price },
        }));
    };

    //step 1: show add to cart if not added
    //step 2: show qty btns if added
    //step 3: if product have options then show qty based on selected options
    const RenderAddCartActions = ({ product }) => {

        if (parseInt(product.InStock) === 0)
            return <button className=" btn theme-btn btn-sm disabled">
                Out of Stock
            </button>


        const addedProductToCart = productsInCart.filter(
            item => parseInt(item.ProductId) === parseInt(product.ProductId)
        );

        //Priority 1: set selected product config from state if found 
        //Priority 2: set selected config if product is added to cart and has config
        //Priority 3: select first options else false
        const selectedConfig = selectedConfigs[product.ProductId]
            || (addedProductToCart?.length && { "Value": addedProductToCart[0]?.PackSize, "Price": addedProductToCart[0]?.ProductPrice })
            || (parseInt(product.PackType) === 1 && product.Configurations?.Configs[0]);

        //check if product is added to cart
        if (addedProductToCart?.length > 0) {

            //check if product with selected size is added
            const productWithSelectedSize = addedProductToCart.find(item => {
                return item.PackSize === selectedConfig?.Value;
            });

            //get the cart product qty
            const addedQty = productWithSelectedSize?.Qty;

            if (productWithSelectedSize || !selectedConfig) {
                return <div className="quantity mb-3">
                    <label className="text-color qty-label d-none">Quantity:</label>
                    <button className="product-qty-decrement-button" onClick={() => updateProductQuantityInCart(productWithSelectedSize?.ItemId, (addedQty - 1), selectedConfig?.Value || productWithSelectedSize?.PackSize)}>
                        <i className="fa fa-minus"></i>
                    </button>
                    <input className="product-qty-text" type="text" disabled value={addedQty} />
                    <button className="product-qty-increment-button" onClick={() => updateProductQuantityInCart(productWithSelectedSize?.ItemId, (addedQty + 1), selectedConfig?.Value || productWithSelectedSize?.PackSize)}>
                        <i className="fa fa-plus"></i>
                    </button>
                </div>
            }
        }


        return <button className="theme-btn btn-sm" onClick={() => {
            addProductsToCart({
                ItemId: uuid.v4(),
                ProductId: product.ProductId,
                ProductImage: `${imagePath}${product.ProductId}/${product.ProductImage}`,
                ProductName:
                    product.ProductName,
                ProductPrice: selectedConfig?.Price || product.Price,
                PackSize:
                    parseInt(
                        product.PackType
                    ) === 1
                        ? selectedConfig?.Value
                        : null,
                Qty: 1,
            });
        }}>Add to Cart</button>
    }



    let products = "";
    let numOfDisplyedProducts = 0;


    if (Array.isArray(productList)) {
        products = productList.map(function (val) {

            numOfDisplyedProducts++;
            
            const addedProductToCart = productsInCart.filter(
                item => parseInt(item.ProductId) === parseInt(val.ProductId)
            );

            //Priority 1: set selected product config from state if found 
            //Priority 2: set selected config if product is added to cart and has config
            //Priority 3: select first options
            const selectedConfig = selectedConfigs[val.ProductId] || (addedProductToCart?.length && { "Value": addedProductToCart[0]?.PackSize, "Price": addedProductToCart[0]?.ProductPrice }) || (parseInt(val.PackType) === 1 && val.Configurations?.Configs[0]);

            return (
                <div
                    className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center"
                    key={`topRatedProduct-${val.ProductId}`}
                >
                    <div className="product-card p-1">
                        <Link
                            className="product-thumbail"
                            to={`/product/view/${val.ProductId}`}
                        >
                            <ProductImageLoader serverImgPath={`${imagePath}${val.ProductId}/`} src={val.ProductImage} />
                            {/* Badge*/}
                            <span className="theme-badge product-thumnail-badge">
                                Top Rated
                            </span>
                        </Link>
                        <div className="product-info">
                            <Link
                                className="product-title d-block text-truncate mt-0"
                                to={`/product/view/${val.ProductId}`}
                            >
                                <h3 className="product-name">{val.ProductName}</h3>
                            </Link>

                            <p className="product-price">₹{selectedConfig?.Price || val.Price}</p>
                            {
                                parseInt(val.PackType) === 1 && <>
                                    <div className="text-color config-label">{val?.Configurations?.TypeName}</div>
                                    <div className="config-options">
                                        {val?.Configurations?.Configs.map((config) => {
                                            return (<div
                                                key={`product-config-${val.ProductId}-${config.Value}`}
                                                className={`config-option ${selectedConfig?.Value === config.Value ? 'selected' : ''}`}
                                                onClick={() => handleConfigSelection(val.ProductId, config.Value, config.Price)}
                                            >
                                                {config.Value}
                                            </div>)
                                        }
                                        )}
                                    </div>
                                </>
                            }

                            <RenderAddCartActions product={val} />
                        </div>
                    </div>
                </div>
            );
        });
    }



    return (
        <div className="page-content-wrapper">
            
            <div
                className="product-page-container"
                id="productContainer"
            >
                {/* Product count component*/}
                <ProductCountHeader
                    displayedProducts={numOfDisplyedProducts}
                    totalProducts={totalNumOfProducts}
                />

                {/* product container */}
                <div className="product-page-container-card">
                    <InfiniteScroll
                        scrollableTarget="productContainer"
                        scrollThreshold={0.9}
                        dataLength={productList?.length || 0}
                        next={() => fetchMoreProducts(loadedPageNo)}
                        hasMore={numOfDisplyedProducts !== totalNumOfProducts}
                        loader={<Skeleton loadMore={true} />}
                    ><div className="row g-3 ">
                            {products}
                        </div>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );
}

export default ProductItem;
