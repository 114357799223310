import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export default function RefundDetailsSkeleton() {
    return (
        <SkeletonTheme highlightColor="#bababa">
            <div className='m-3 row'>
                <div className='col-sm-6'>
                    <Skeleton count={3} width={'100%'} height={200} className="mb-3" />
                </div>
                <div className='col-sm-6'>
                    <Skeleton count={3} width={'100%'} height={200} className="mb-3" />
                </div>

            </div>

        </SkeletonTheme>
    )
}