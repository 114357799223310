import {
    BEGIN_ACTION_IN_ORDER_DATA_REDUCER,
    SUCCESS_GET_PAYMENT_METHODS,
    SUCCESS_GET_DELIVERY_METHODS,
    SUCCESS_GET_SHIPPING_CONFIGS,
    SUCCESS_GET_ORDER_HISTORY,
    SUCCESS_GET_ORDER_HISTORY_BY_ORDER_ID,
    SUCCESS_GET_ORDER_DETAILS,
    SUCCESS_REFETCH_ORDER_DETAILS,
    ADD_PLACED_ORDERS_COUNT,
    EXCEPTION_GENERATED_IN_ORDER_DATA_REDUCER,
    CLEAR_IS_ORDER_DETAILS_FOUND,
    CLEAR_EXCEPTION_AND_LOADING_STATE,
    CLEAR_DATA_IN_ORDER_DATA_REDUCER
} from "../constants";

const initialState = {
    loading: false,
    exception: false,
    isOrderDetailsFound: null,
    countPlacedOrders: 0,
    countTotalOrders: 0,
    pageNoOfFetchedOrder: 0,
    shippingConfigs:null,
    paymentMethods: [],
    deliveryMethods: [],
    orderHistoryData: null,
    orderDetails: []
};

const orderDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case BEGIN_ACTION_IN_ORDER_DATA_REDUCER:
            return {
                ...state,
                loading: true,
                exception: false,
            };

        case SUCCESS_GET_PAYMENT_METHODS:
            return {
                ...state,
                loading: false,
                exception: false,
                paymentMethods: action.payload
            }

        case SUCCESS_GET_DELIVERY_METHODS:
            return {
                ...state,
                loading: false,
                exception: false,
                deliveryMethods: action.payload
            }

        case SUCCESS_GET_SHIPPING_CONFIGS:
            return {
                ...state,
                loading: false,
                exception: false,
                shippingConfigs: action.payload
            }

        case SUCCESS_GET_ORDER_HISTORY:
            return {
                ...state,
                loading: false,
                exception: false,
                orderHistoryData: action.payload.orders,
                pageNoOfFetchedOrder: action.payload.fetchedPageNo,
                countTotalOrders: action.payload.countTotalOrders
            }

        case SUCCESS_GET_ORDER_HISTORY_BY_ORDER_ID:
            return {
                ...state,
                loading: false,
                exception: false,
                orderHistoryData: action.payload
            }

        case SUCCESS_GET_ORDER_DETAILS:
            return {
                ...state,
                loading: false,
                exception: false,
                orderDetails: action.payload.orderDetailsList,
                isOrderDetailsFound: action.payload.isOrderDetailsFound
            }

        case SUCCESS_REFETCH_ORDER_DETAILS:
            return {
                ...state,
                loading: false,
                exception: false,
                paymentProcessing: false,
                orderDetails: action.payload
            }

        case CLEAR_IS_ORDER_DETAILS_FOUND:
            return {
                ...state,
                isOrderDetailsFound: action.payload
            }

        case ADD_PLACED_ORDERS_COUNT:
            return {
                ...state,
                loading: false,
                exception: false,
                countPlacedOrders: action.payload
            }

        case CLEAR_DATA_IN_ORDER_DATA_REDUCER:
            return {
                ...state,
                loading: false,
                exception: false,
                isOrderDetailsFound: null,
                countPlacedOrders: 0,
                countTotalOrders: 0,
                pageNoOfFetchedOrder: 0,
                orderHistoryData: null,
                orderDetails: []
            }

        case EXCEPTION_GENERATED_IN_ORDER_DATA_REDUCER:
            return {
                ...state,
                loading: false,
                exception: action.payload,
            };

        case CLEAR_EXCEPTION_AND_LOADING_STATE:
            return {
                ...state,
                loading: false,
                exception: false,
            };

        default:
            return state;
    }
}

export default orderDataReducer;

