import {
    BEGIN_ACTION_IN_ADDRESS_REDUCER,
    SUCCESS_POST_USER_ADDRESS,
    SUCCESS_GET_USER_ADDRESSES,
    REDIRECT_TO_UPDATE_ADDRESS,
    SUCCESS_UPDATE_USER_ADDRESSES,
    SUCCESS_DELETE_USER_ADDRESSES,
    SUCCESS_GET_MAP_API_KEY,
    EXCEPTION_GENERATED_IN_ADDRESS_REDUCER,
    CLEAR_EXCEPTION_AND_LOADING_STATE,
    CLEAR_ADDRESS_TO_BE_UPDATE,
    CLEAR_DATA_IN_ADDRESS_REDUCER,
    SUCCESS_SET_DEFAULT_ADDRESS,
    CLEAR_ADDED_UPDATED_ADDRESS,

} from "../constants";

const initialState = {
    loading: false,
    exception: false,
    mapApiKey: null,
    addressIdToUpdateAddress: null,
    addressList: null,
    newAddress: null,
    updatedAddress: null

};

const addressReducer = (state = initialState, action) => {
    switch (action.type) {
        case BEGIN_ACTION_IN_ADDRESS_REDUCER:
            return {
                ...state,
                loading: true,
                exception: false,
            };

        case SUCCESS_POST_USER_ADDRESS:
            return {
                ...state,
                loading: false,
                exception: false,
                addressList: action.payload.addressList,
                newAddress: action.payload.newAddress
            };

        case SUCCESS_GET_USER_ADDRESSES:
            return {
                ...state,
                loading: false,
                addressList: action.payload
            }

        case SUCCESS_GET_MAP_API_KEY:
            return {
                ...state,
                loading: false,
                mapApiKey: action.payload
            }

        case REDIRECT_TO_UPDATE_ADDRESS:
            return {
                ...state,
                loading:false,
                addressIdToUpdateAddress: action.payload.addressId
            }

        case SUCCESS_UPDATE_USER_ADDRESSES:
            return {
                ...state,
                loading: false,
                addressList: action.payload.addressList,
                updatedAddress: action.payload.updatedAddress
            }

        case CLEAR_ADDED_UPDATED_ADDRESS:
            return {
                ...state,
                loading:false,
                newAddress: null,
                updatedAddress: null
            }

        case CLEAR_ADDRESS_TO_BE_UPDATE:
            return {
                ...state,
                loading:false,
                addressIdToUpdateAddress: null
            }

        case SUCCESS_DELETE_USER_ADDRESSES:
            return {
                ...state,
                loading: false,
                addressList: action.payload.addressList
            }

        case SUCCESS_SET_DEFAULT_ADDRESS:
            return {
                ...state,
                loading: false,
                addressList: action.payload.addressList
            }

        case CLEAR_DATA_IN_ADDRESS_REDUCER:
            return {
                ...state,
                selectedAddress: false,
                addressIdToUpdateAddress: null,
                addressList: null
            }

        case EXCEPTION_GENERATED_IN_ADDRESS_REDUCER:
            return {
                ...state,
                loading: false,
                exception: action.payload,
            };

        case CLEAR_EXCEPTION_AND_LOADING_STATE:
            return {
                ...state,
                loading: false,
                exception: false,
            };

        default:
            return state;
    }
};

export default addressReducer;