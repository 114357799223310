import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { serverContext } from '../../context/serverContext';
import { modalContext } from '../../context/modalContext';
import { checkoutOrder, clearOrderProcessReducersData, paymentProcess, paymentCanceled, updateFailedPaymentStatus, validatePayment, paymentProcessEnd } from '../../redux/actions/orderProcessActions';
import { getPaymentMethods, addPlacedOrdersCount, getOrderHistoryByOrderId } from '../../redux/actions/orderDataActions';
import { decrypt, isDecryptedDataValid } from '../functions/cipherFunctions';
import { clearOfferReducersData } from '../../redux/actions/offerActions';
import { cartItemContext } from '../../context/cartItemContext';
import { authContext } from '../../context/authContext';
import { useHistory } from 'react-router-dom';
import OrderAmount from '../cart/OrderAmount';
import PaymentProcessingDialog from '../popupDialogs/PaymentProcessingDialog';
import CancelationPoliciesDialog from '../popupDialogs/CancelationPoliciesDialog';
import OrderProcess from './OrderProcess';
import PaymentMethods from './PaymentMethods';


function Checkout() {

	const dispatch = useDispatch();
	const history = useHistory();

	const { serverPath } = useContext(serverContext);
	const { isAuthenticate } = useContext(authContext)
	const { showLoading, hideLoading, showErrorDialog } = useContext(modalContext);
	const { productsInCart, clearCartItems } = useContext(cartItemContext);

	//get and decrypt order data from reducer
	const getOrderData = useSelector(state => state.orderProcessReducer.orderData);
	const orderData = getOrderData && isDecryptedDataValid(decrypt(getOrderData, true));

	//get and decrypt available payment methods from reducer
	const getPaymentMethodsData = useSelector(state => state.orderDataReducer.paymentMethods);
	const paymentMethods = getPaymentMethodsData && isDecryptedDataValid(decrypt(getPaymentMethodsData, true));

	//get and decrypt order history data from reducer
	const getOrderHistoryData = useSelector(state => state.orderDataReducer.orderHistoryData);
	const orderHistoryList = getOrderHistoryData ? isDecryptedDataValid(decrypt(getOrderHistoryData, true)) : null;

	//get and decrypt selected address from reducer
	const getSelectedAddressFromReducer = useSelector(state => state.orderProcessReducer.selectedAddress);
	const selectedAddress = getSelectedAddressFromReducer && isDecryptedDataValid(decrypt(getSelectedAddressFromReducer, true));

	//get and decrypt shipping configurations from reducer
	const getStoreConfigsData = useSelector(state => state.homeReducer.storeConfigs);
	const storeConfigs = getStoreConfigsData && isDecryptedDataValid(decrypt(getStoreConfigsData, true));

	//get data about order processes from reducer
	const isPaymentProcessing = useSelector((state) => state.orderProcessReducer.paymentProcessing);
	const isPaymentCanceled = useSelector((state) => state.orderProcessReducer.paymentCanceled);
	const isPaymentFailed = useSelector((state) => state.orderProcessReducer.paymentFailed);
	const exception = useSelector((state) => state.orderProcessReducer.exception);
	const processedOrder = useSelector((state) => state.orderProcessReducer.processedOrder);
	const placedOrder = useSelector((state) => state.orderProcessReducer.placedOrder);
	const countPlacedOrders = useSelector(state => state.orderDataReducer.countPlacedOrders);

	//set references variables to check order status
	const isOrderProcessed = useRef(false);
	const paymentFailed = useRef(false);
	const isNewOrderHistoryFetched = useRef(false);

	//states to store paymentType, order note and no payment element
	const [orderNote, setOrderNote] = useState('');
	const [noPaymentElement, setNoPaymentElement] = useState(false);

	//set initial payment type as Razorpay if more than 1 payment methods available else set payment method which is available
	const [paymentType, setPaymentType] = useState('');

	//function to set payment type
	const handlePaymentTypeChange = (type) => {

		setPaymentType(type);
	}

	//function to set order note
	const handleOrdrNoteChange = (note) => {
		setOrderNote(note)
	}

	//initiate checkout
	const initiateCheckout = (e) => {

		e.preventDefault();

		//show privacy and canelation pop-up if it now showed already else initiate checkout process
		if (!localStorage.getItem("isPrivacyPopupShowedAlready"))
			showCancalationPolicy();
		else
			showPaymentProcessingNotice();
	}

	//show cancelation policy pop-up
	const showCancalationPolicy = () => {

		return window.$("#cancelationPolicyModal").modal("show");
	}

	//function to show payment processing popup until order processing and creating in server
	const showPaymentProcessingNotice = () => {

		window.$("#cancelationPolicyModal").modal("hide");

		//if user is offline then show warning
		if (!window.navigator.onLine) {
			showErrorDialog(
				"You are offline",
				"Please check your internet connection and try again"
			);
			return;
		}

		//if paymenty type is Pay on delivery then skip payment process and create order in server
		if (paymentType === "Pay on delivery") {
			showLoading();
			createOrder();
		} else {

			dispatch(paymentProcess());

			//show payment processing dialog
			window.$("#paymentProcessingDialog").modal("show");

			//call createOrder function after 3 seconds to create order in server
			setTimeout(() => {
				createOrder();
			}, 3000);
		}
	}

	//function to create order in server
	const createOrder = () => {

		//add payment type and order note in current order data
		orderData.paymentType = paymentType;
		orderData.orderNote = orderNote;

		//call action to create order in server
		dispatch(checkoutOrder(serverPath, orderData));

		isOrderProcessed.current = true;
	}

	//function to invoke razorpay popup for payment process
	const invokeRazorpay = (isRevise = false) => {

		//Invoke Razorpay if current processed order has Razorpay payment method selected
		if (processedOrder?.keyId && processedOrder?.amount && processedOrder?.razorpayOrderId) {
			const options = {
				"key": processedOrder?.keyId,
				"amount": processedOrder?.amount,
				"currency": "INR",
				"order_id": processedOrder?.razorpayOrderId,
				"name": storeConfigs?.storeName,
				"description": "Thanks for your purchase",
				"image": storeConfigs?.storeLogo,
				//handler for if payment successfully processed
				"handler": function (response) {

					//current order id
					response.orderId = processedOrder?.orderId;

					//dispatch action to validate payment
					dispatch(validatePayment(serverPath, response));
				},
				"prefill": {
					"name": isAuthenticate.username,
					"email": isAuthenticate.email || selectedAddress.Email || '',
					"contact": isAuthenticate.phoneNumber || selectedAddress.PhoneNumber
				},
				"theme": {
					"color": localStorage.getItem('theme') === 'dark' ? "#061238" : '#06227a'
				},
				"modal": {
					//event listner to detect when customer close payment pop-up
					"ondismiss": function () {
						console.log('Payment pop-up closed');

						if (paymentFailed.current !== true)
							dispatch(paymentCanceled());
						else
							dispatch(paymentProcessEnd(paymentFailed.current));


						//fetch current orders history data if orderHistoryList if not null
						if (isNewOrderHistoryFetched.current === false && isOrderProcessed.current && orderHistoryList?.length) {
							dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, processedOrder?.orderId, orderHistoryList));
							dispatch(addPlacedOrdersCount(countPlacedOrders + 1));
						}

						isNewOrderHistoryFetched.current = true;
					}
				}
			};

			if (isRevise)
				dispatch(paymentProcess());

			const rzp = new window.Razorpay(options);
			rzp.open(options);


			rzp.on('payment.failed', function (response) {
				const params = {
					orderId: processedOrder?.orderId,
					status: "Failed",
					rzpOrderId: response.error?.metadata?.order_id,
					rzpPaymentId: response.error?.metadata?.payment_id
				}

				paymentFailed.current = true;

				//dispatch action to update payment status as failed for current order
				dispatch(updateFailedPaymentStatus(serverPath, params));

				//fetch current orders history data if orderHistoryList if not null
				if (isNewOrderHistoryFetched.current === false && isOrderProcessed.current && orderHistoryList?.length) {
					dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, processedOrder?.orderId, orderHistoryList));
					dispatch(addPlacedOrdersCount(countPlacedOrders + 1));
				}
				else if (isOrderProcessed.current && orderHistoryList?.length) {
					setTimeout(() => {
						const reFetch = true;
						dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, processedOrder?.orderId, orderHistoryList, reFetch));
					}, 1000);
				}
				isNewOrderHistoryFetched.current = true;
			});

		}
		else {
			showErrorDialog(
				'Internal server error',
				'Something went wrong, Please try again after some time'
			);
		}

	}

	//scroll to top when component loads
	useEffect(() => {
		window.scrollTo(0, 0);
		//eslint-disable-next-line
	}, [])

	//fetch available payment methods from server if already not fetched
	useEffect(() => {
		if (!paymentMethods) {
			dispatch(getPaymentMethods(serverPath));
		}
		//eslint-disable-next-line
	}, [])

	//redirect to cart page if cart data is not proccessed
	useEffect(() => {
		if (window.cartProccessed !== true)
			history.replace('/cart');

		//eslint-disable-next-line
	}, [productsInCart])

	//Monitor processedOrder to Invoke razorpay popup for payment when order created successfully in server
	useEffect(() => {
		if (isOrderProcessed.current && paymentType !== "Pay on delivery") {
			window.$("#paymentProcessingDialog").modal("hide");
			invokeRazorpay();
		}

		if (isOrderProcessed.current) {
			//clear offer and order data from reducers
			dispatch(clearOfferReducersData());

			//clear cart
			clearCartItems();
		}

		//clear order process data when user leave the checkout page after 
		//payment process initiated or payment failed/canceled
		return () => {
			if (isPaymentProcessing || isPaymentCanceled || isPaymentFailed) {
				window.cartProccessed = false;
				dispatch(clearOrderProcessReducersData());
			}
		}

		//eslint-disable-next-line
	}, [processedOrder])

	//Monitor placedOrder to redirect users to success page if payment is captured and order completed
	useEffect(() => {
		if (placedOrder?.Success) {

			history.push('/order/confirmed');

			//fetch current orders history data if orderHistoryList is not null
			if (isNewOrderHistoryFetched.current === false && isOrderProcessed.current && orderHistoryList?.length) {
				dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, processedOrder?.orderId, orderHistoryList));
				dispatch(addPlacedOrdersCount(countPlacedOrders + 1));
			}
			else if (isOrderProcessed.current && orderHistoryList?.length) {
				setTimeout(() => {
					const reFetch = true;
					dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, processedOrder?.orderId, orderHistoryList, reFetch));
				}, 1000);
			}

			//clear cart
			clearCartItems();

			//set globar cart processed variable
			window.cartProccessed = false;

			isOrderProcessed.current = false;
		}
		//eslint-disable-next-line
	}, [placedOrder]);

	//Show error dialog for any excetion generated
	if (exception) {
		hideLoading();
		window.$("#cancelationPolicyModal").modal("hide");
		window.$("#paymentProcessingDialog").modal("hide");

		showErrorDialog(
			exception.message,
			exception.description
		);
		dispatch({
			type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
		});

		history.push('/cart')
	}

	//Redirect users to success page when order completed with Pay on delivery payment type 
	if (!exception && isOrderProcessed.current && paymentType === "Pay on delivery") {
		hideLoading();

		history.push(`/order/confirmed`);

		//fetch current orders history data if not already fetched and orderHistoryList not null
		if (isNewOrderHistoryFetched.current === false && isOrderProcessed.current && orderHistoryList?.length) {
			dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, processedOrder?.orderId, orderHistoryList));
			dispatch(addPlacedOrdersCount(countPlacedOrders + 1));
		}

		//clear cart
		clearCartItems();

		//set globar cart processed variable
		window.cartProccessed = false;

		isOrderProcessed.current = false;
	}


	return (
		<>
			<PaymentProcessingDialog />
			<CancelationPoliciesDialog history={history} checkoutCallback={showPaymentProcessingNotice} />

			<div className="page-content-wrapper">
				<div className="row justify-content-center">
					<div className="col-md-6 col-lg-6">
						{
							//Show order process component if order is created on server and payment is pending
							!exception && (isPaymentProcessing || isPaymentFailed || isPaymentCanceled) ? <>

								<OrderProcess paymentProcessing={isPaymentProcessing} paymentFailed={isPaymentFailed} paymentCanceled={isPaymentCanceled} revisePayment={invokeRazorpay} />
								<OrderAmount subTotal={orderData?.orderAmount?.subTotal} discount={orderData?.orderAmount?.discount} deliveryCharge={orderData?.orderAmount?.deliveryCharge} taxAndCharge={orderData?.orderAmount?.taxAndCharge} shippingType={orderData?.shippingType} totalPayableAmount={orderData?.orderAmount?.total} testOrderAmount={isAuthenticate?.isTestUser?.TestOrderAmount} />
							</> : <>
								<PaymentMethods methods={paymentMethods} handlePaymentTypeChange={handlePaymentTypeChange} selectedShippingType={orderData?.shippingType} setNoPaymentElement={setNoPaymentElement} />
								<div className="card mt-1">
									<div className='card-header'>
										Order Note
									</div>
									<div className="card-body">
										<div className="form-group m-0 p-0">
											<textarea className="form-control" name="orderNote" placeholder='Write suggestion to us for your current order (optional)' maxLength={250} value={orderNote} onChange={(e) => handleOrdrNoteChange(e.target.value)} />
										</div>
									</div>
								</div>

								<OrderAmount subTotal={orderData?.orderAmount?.subTotal} discount={orderData?.orderAmount?.discount} deliveryCharge={orderData?.orderAmount?.deliveryCharge} taxAndCharge={orderData?.orderAmount?.taxAndCharge} shippingType={orderData?.shippingType} totalPayableAmount={orderData?.orderAmount?.total} testOrderAmount={isAuthenticate?.isTestUser?.TestOrderAmount} />

								<div className='card'>
									<div className='card-body'>
										{isAuthenticate?.isTestUser && <p className='text-danger text-center fw-bold '>You are placing an test order with ₹{isAuthenticate?.isTestUser?.TestOrderAmount}</p>}
										<button className='btn custom-btn-style w-100' onClick={initiateCheckout} disabled={!paymentType || noPaymentElement === true ? true : false}>Checkout</button>
									</div>
								</div>
							</>
						}

					</div>
				</div>
			</div>
		</>
	)
}

export default Checkout