import React from 'react'

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import { clearProcessedOrder } from '../redux/actions/orderProcessActions';
import { decrypt, isDecryptedDataValid } from './functions/cipherFunctions';



export default function OrderPlacedSuccess() {

    const displatch = useDispatch();
    const history = useHistory();

    //get and decrypt shipping configurations from reducer
    const getStoreConfigsData = useSelector(state => state.homeReducer.storeConfigs);
    const storeConfigs = getStoreConfigsData && isDecryptedDataValid(decrypt(getStoreConfigsData, true));

    const processedOrder = useSelector((state) => state.orderProcessReducer.processedOrder);
    const createdOrder = processedOrder?.createdOrder;

    useEffect(() => {

        if (!createdOrder?.orderId)
            history.push('/order/history')

        return () => {
            displatch(clearProcessedOrder());
        }
        //eslint-disable-next-line
    }, [processedOrder])


    return (
        <div className="page-content-wrapper">
            <div className="card">
                <div className="single-page-wrapper card-body">
                    <div className="justify-content-center">
                    <h5>Your order is confirmed!</h5>
                        <svg width="60" height="60" viewBox="0 0 16 16" className="mt-3 bi bi-check-circle-fill text-success mb-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                        
                        <p>Your order number is <strong>{createdOrder?.orderNumber}</strong>.</p>
                        <p>Thank you for choosing us! Your order is being freshly prepared and will be delivered to you shortly.</p>
                        <Link to={`/order/details/${createdOrder?.orderId}`} className="btn btn-primary">View Order Details</Link>

                        <h6 className="mt-4">Helpful Tips</h6>
                        <p>If you're not using these items immediately, store it in the refrigerator. Use within 24 hours for the best quality.</p>
                        <p className='mt-4'>If you have any questions, please email us at <strong>{storeConfigs?.storeEmail}</strong> or call our support team at <strong>{storeConfigs?.storePhone}</strong>. We're here to help!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


