import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function ProductDetailsSkeleton() {
    const ProductDetailsSkeleton = () => {
        return (
            <>
                <div className="card py-1">
                    <div className="card-body">
                        <div className="product-gallery owl-carousel">
                            <div className="single-product-image text-center">
                                <Skeleton style={{ height: "40vh" }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mt-1">
                    <div className="card-body">
                        <h3>
                            <Skeleton width={200} />
                        </h3>
                        <h1>
                            <Skeleton width={150} />
                        </h1>

                        <Skeleton className="mb-2" height={30} count={2} />
                    </div>
                </div>
            </>
        );
    };

    const AverageRatingSkeleton = () => {
        return (
            <>
                <div className="card mt-1">
                    <div className="card-body">
                        <h5>
                            <Skeleton width={200} />
                        </h5>
                        <div className="rating-card-two mt-4">
                            <div className="d-flex align-items-center justify-content-between mb-3 border-bottom pb-2">
                                <div className="rating">
                                    <Skeleton width={100} />
                                </div>
                                <span>
                                    <Skeleton width={100} />
                                </span>
                            </div>

                            <Skeleton count={5} />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const TopReviewSkeleton = () => {
        return (
            <>
                <div className="card mt-1">
                    <div className="card-body">
                        <h2>
                            <Skeleton width={150} />
                        </h2>
                        <div className="carousel slide">
                            <div className="carousel-inner">
                                <Skeleton width={100} className="mt-2 mb-2" />
                                <Skeleton count={3} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const ReviewsSkeleton = () => {
        return (
            <>
                <div className="card mt-1">
                    <div className="card-body">
                        <h2>
                            <Skeleton width={200} />
                        </h2>
                        <small className="ms-1">
                            <Skeleton width={150} />
                        </small>
                        <div
                            style={{
                                maxHeight: "60vh",
                                overflow: "scroll",
                            }}
                            className="mt-1"
                        >
                            <ProductReviewsSkeleton />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <SkeletonTheme highlightColor="#bababa">
            <ProductDetailsSkeleton />
            <AverageRatingSkeleton />
            <TopReviewSkeleton />
            <ReviewsSkeleton />
        </SkeletonTheme>
    );
}

export const ProductReviewsSkeleton = () => {
    let reviewsSkeletons = [];

    for (let i = 0; i < 10; i++) {
        reviewsSkeletons.push(
            <div
                className="single-testimonial-slide mb-3"
                key={`productReview-skeleton-${i}`}
            >
                <div className="text-content">
                    <span className="d-block mb-1">
                        <Skeleton circle={true} height={25} width={25} />
                        <span className="ms-2">
                            <Skeleton width={80} />
                        </span>
                    </span>
                    <span className="d-inline-block mb-2">
                        <Skeleton width={100} />
                        <small className="ms-2">
                            <Skeleton width={130} />
                        </small>
                    </span>
                    <p className="mb-2 lh-sm">
                        <Skeleton count={3} />
                    </p>
                </div>
            </div>
        );
    }

    return (
        <SkeletonTheme highlightColor="#bababa">
            {reviewsSkeletons}
        </SkeletonTheme>
    );
};
