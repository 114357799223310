import React from "react";
import ShowMoreText from "react-show-more-text";

import { ProductReviewsSkeleton } from "./Skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import RatingStar from "./RatingStar";

function AllReview(props) {

    let reviewList = "";

    if (Array.isArray(props.reviews) && props.reviews.length > 0) {
        reviewList = props.reviews.map((obj) => {

            return (
                <div
                    className="single-testimonial-slide mb-3"
                    key={`productReview-${obj.RatingId}`}
                >
                    <div className="text-content">
                        <span className="d-block mb-1">
                            {obj.Username}
                        </span>
                        <span className="d-inline-block mb-2">
                            {/* component to Print stars from given Ratings */}
                            <RatingStar Ratings={obj.Ratings} />

                            <small className="ms-2">{obj.CreatedAt}</small>
                        </span>
                        <ShowMoreText
                            lines={5}
                            more={<b>More</b>}
                            less={<b>Less</b>}
                            className="tuncatedOfferDetails text-secondary"
                            anchorClass="text custom-text-style"
                            expanded={false}
                            truncatedEndingComponent={"... "}
                        >
                            <p className="mb-2 lh-md">
                                {
                                    //replace angular brackets and print review
                                    obj.Review.replace(/&lt;/g, "<").replace(
                                        /&gt;/g,
                                        ">"
                                    )
                                }
                            </p>
                        </ShowMoreText>
                    </div>
                </div>
            );
        });
    } else {
        reviewList = (
            <h3 className="text-danger mt-3 text-center">
                No reviews posted yet
            </h3>
        );
    }

    return (
        <>
            <div className="card mt-1"  id="reviews">
                <div
                    style={{
                        position: "sticky",
                        top: 0,
                    }}
                >
                    <h2 className="ms-4 mt-4">Customers Reviews</h2>
                    {/*label of displayed review */}
                    <div className="shop-pagination pb-1">
                        <div className="card">
                            <div className="card-body p-2">
                                <div className="d-flex align-items-center justify-content-between">
                                    <small className="ms-4">
                                        Showing{" "}
                                        <span>
                                            {Array.isArray(props.reviews)
                                                ? props.reviews.length
                                                : 0}
                                        </span>{" "}
                                        out of{" "}
                                        <span>
                                            {props.totalNumberOfReviews}
                                        </span>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* *label of displayed review */}
                </div>

                <div
                    className="card-body"
                    style={{ maxHeight: "70vh", overflow: "auto" }}
                    id="reviewContainer"
                >
                    {/* Review container */}
                    <div className="mt-1">
                        <InfiniteScroll
                            scrollableTarget="reviewContainer"
                            scrollThreshold={0.9}
                            dataLength={
                                Array.isArray(props.reviews)
                                    ? props.reviews.length
                                    : 0
                            }
                            next={props.loadMoreReviews}
                            hasMore={
                                Array.isArray(props.reviews) &&
                                parseInt(props.reviews.length) !==
                                parseInt(props.totalNumberOfReviews)
                            }
                            loader={<ProductReviewsSkeleton />}
                        >
                            {reviewList}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AllReview;
