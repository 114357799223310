import React from "react";
import { Link } from "react-router-dom";

function AverageRating({ ratings }) {
    let stars = [];
    let individualRatingPer = [
        ratings.FiveStar,
        ratings.FourStar,
        ratings.ThreeStar,
        ratings.TwoStar,
        ratings.OneStar,
    ];
    let counter = 0;
    let averageRating = ratings.AverageRating;

    //loop to print filled starts as per ratings (EX: rating=3 then it will print 3 filled stars)
    for (let i = 1; i <= 5; i++) {
        if (i <= averageRating) {
            stars.push(
                <Link to="#" key={`ratingInStar-${counter}`}>
                    <i className="fa fa-star"></i>
                </Link>
            );
            counter++;
        }
    }

    //loop to print remaining stars out of 5
    while (counter < 5) {
        //if rating contains pointing value then print half filled star (Ex: rating = 3.5) else print empty stars
        if (counter < averageRating)
            stars.push(
                <Link to="#" key={`ratingInStar-${counter}`}>
                    <i className="fa fa-star-half-o"></i>
                </Link>
            );
        else
            stars.push(
                <Link to="#" key={`ratingInStar-${counter}`}>
                    <i className="fa fa-star-o"></i>
                </Link>
            );

        counter++;
    }

    //print individual stars percentage (printing as reverce  5 to 1)
    counter = 6;
    const displayIndividualRatingPer = individualRatingPer.map((obj) => {
        counter--;

        return (
            <div
                className="rating-detail"
                key={`individualRatingPer-${counter}`}
            >
                <div className="d-flex align-items-center mt-2">
                    <span>{counter} star</span>
                    <div className="progress-bar-wrapper">
                        <div className="progress">
                            <div
                                className="progress-bar bg-warning"
                                style={{ width: obj + "%" }}
                                role="progressbar"
                                aria-valuenow="78"
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                    </div>
                    <span>{obj} %</span>
                </div>
            </div>
        );
    });

    return (
        <>
            <div className="card mt-1">
                <div className="card-body">
                    <h5>Product Ratings</h5>
                    <div className="rating-card-two mt-4">
                        <div className="d-flex align-items-center justify-content-between mb-3 border-bottom pb-2">
                            <div className="rating">{stars}</div>
                            <span>{averageRating} out of 5 ratings</span>
                        </div>

                        {displayIndividualRatingPer}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AverageRating;
