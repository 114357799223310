import React from 'react'
import PaymentProcessingSkeleton from './PaymentProcessingSkeleton';

export default function PaymentDetails({ paymentDetails, paymentType, paymentStatus, noDetails, initiatePayment, paymentProcessing, orderStatus, rzpRefundId }) {

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const toggleErrorDetails = () => {
        window.$(".payment_error_details").toggleClass('hide');
    }

    if (paymentProcessing) {
        return <PaymentProcessingSkeleton />
    }

    let paymentStatusElement = '';
    switch (paymentStatus) {
        case 'Failed':
            paymentStatusElement = <span className="badge bg-danger payment_error" onClick={() => toggleErrorDetails()}>{paymentStatus} <span className='payment_error'><i className='fa fa-info-circle ms-1'></i></span></span>;
            break;

        case 'Pending':
            paymentStatusElement = <span className="badge bg-warning">{paymentStatus}</span>;
            break;

        case 'Refunded':
            paymentStatusElement = <span className="badge bg-primary">{paymentStatus}</span>;
            break;

        case '4':
            paymentStatusElement = <span className="badge bg-success">{paymentStatus}</span>;
            break;

        case 'Unauthorized':
            paymentStatusElement = <span className="badge bg-danger">{paymentStatus}</span>;
            break;

        case 'Paid':
            paymentStatusElement = <span className="badge bg-success">{paymentStatus}</span>;
            break;

        default:
            paymentStatusElement = <span className="badge bg-danger">Unknown</span>;
    }

    return (
        <div className='card mt-1'>
            <div className='card-header'>
                Payment Details
            </div>
            <div className='p-3 pb-0 row'>
                {
                    noDetails ? <div className='text-center p-3'><h6 className='text-warning'>Your payment is pending</h6><h6><small className=''>If you already completed the payment then details will be display here soon, Otherwise click on below button to process the payment for your order</small></h6></div> :
                        <>
                            <table className="table table-striped">
                                <tbody>
                                    <tr>
                                        <th>Status</th>
                                        <td >{paymentStatusElement}</td>
                                    </tr>
                                    {paymentDetails ? <>

                                        {
                                            rzpRefundId && <tr>
                                                <th>Refund ID</th>
                                                <td>{rzpRefundId}</td>
                                            </tr>
                                        }

                                        <tr>
                                            <th>Pay ID</th>
                                            <td>{paymentDetails?.id}</td>
                                        </tr>

                                        <tr>
                                            <th>Method</th>
                                            <td>

                                                {paymentDetails?.wallet && (paymentDetails?.method === "cardless_emi" ? "Cardless EMI" : (paymentDetails?.method === "paylater" ? "Pay Later" : paymentDetails?.method))}
                                                {paymentDetails?.vpa && paymentDetails?.method.toString().toUpperCase()}
                                                {paymentDetails?.bank && 'Net Banking'}
                                                {paymentDetails?.card_id && <><span>{capitalizeFirstLetter(paymentDetails?.card?.type)}</span><span className='ms-1'>{capitalizeFirstLetter(paymentDetails?.method)}</span></>}

                                                {/* No known payment method */}
                                                {!paymentDetails?.wallet && !paymentDetails?.vpa && !paymentDetails?.bank && !paymentDetails?.card_id && paymentDetails?.method.toString().toUpperCase()}
                                            </td>
                                        </tr>
                                        {
                                            paymentDetails?.wallet && <>
                                                <tr>
                                                    <th>Provider</th>
                                                    <td>{paymentDetails?.wallet}</td>
                                                </tr>
                                            </>
                                        }
                                        {
                                            paymentDetails?.vpa && <>
                                                <tr>
                                                    <th>UPI ID</th>
                                                    <td>{paymentDetails?.vpa}</td>
                                                </tr>
                                            </>
                                        }
                                        {
                                            paymentDetails?.bank && <>
                                                <tr>
                                                    <th>Bank</th>
                                                    <td>{paymentDetails?.bank}</td>
                                                </tr>
                                            </>
                                        }
                                        {
                                            paymentDetails?.card_id && <>
                                                <tr>
                                                    <th>Type</th>
                                                    <td>{paymentDetails?.card?.network}</td>
                                                </tr>
                                                {
                                                    paymentDetails?.card?.name && <>
                                                        <tr>
                                                            <th>Name</th>
                                                            <td>{paymentDetails?.card?.name}</td>
                                                        </tr>
                                                    </>
                                                }
                                                <tr>
                                                    <th>Number</th>
                                                    <td>xxxx xxxx xxxx {paymentDetails?.card?.last4}</td>
                                                </tr>
                                            </>
                                        }

                                    </> :
                                        <>
                                            <tr>
                                                <th>Method</th>
                                                <td>{paymentType}</td>
                                            </tr>
                                        </>}

                                </tbody>
                            </table>

                            {paymentDetails?.error_description &&
                                <p style={{ lineHeight: 1.6 }}>
                                    <span className='payment_error_details'> {paymentDetails?.error_description}</span>
                                </p>
                            }
                        </>
                }
            </div>

            {parseInt(orderStatus) === 1 && paymentType === "Razorpay" && paymentStatus === "Failed" && <button className='btn btn-danger m-3 mt-0' onClick={(e) => initiatePayment(e)}>Revise Payment</button>}
            {parseInt(orderStatus) === 1 && paymentType === "Razorpay" && paymentStatus === "Pending" && <button className='btn btn-primary m-3 mt-0' onClick={(e) => initiatePayment(e)}>Complete Payment</button>}
        </div>
    )
}
