import React from "react";

//import css files
import "../../assets/css/bootstrap-icons.css";
import "../../assets/css/style.css";
import "../../assets/css/dialog.css";
import "../../assets/css/custom.css";
import "../../assets/css/theme.css";


//Added in index.html
//import "../../assets/css/font-awesome.min.css";
//import "../../assets/css/bootstrap.min.css";

import "jquery";

//import components
import Loader from "../popupDialogs/Loader";
import ErrorDialog from "../popupDialogs/ErrorDialog";
import SuccessDialog from "../popupDialogs/SuccessDialog";
import DarkModeSwitching from "./DarkModeSwitching";
import HeaderArea from "./HeaderArea";
import SideNav from "./SideNav";
import { useSelector } from "react-redux";
import { decrypt, isDecryptedDataValid } from "../functions/cipherFunctions";

function Header() {

    //get and decrypt shipping configurations from reducer
    const getStoreConfigsData = useSelector(state => state.homeReducer.storeConfigs);
    const storeConfigs = getStoreConfigsData && isDecryptedDataValid(decrypt(getStoreConfigsData, true));

    return (
        <>
            <Loader />
            <ErrorDialog />
            <SuccessDialog />
            <HeaderArea storeName={storeConfigs?.storeName} storeLogo={storeConfigs?.storeLogo} />
            <DarkModeSwitching />
            <SideNav />
        </>
    );
}

export default Header;
