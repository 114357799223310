import React from "react";

function RatingStar({ Ratings }) {
    let stars = [];

    //loop to print filled starts as per ratings (EX: If rating = 3 then it will print 3 filled stars and 2 empty stars)
    for (let i = 1; i <= 5; i++) {
        if (i <= Ratings)
            stars.push(
                <span className="ms-1" key={`ratingOfReviewInStar-${i}`}>
                    <i className="fa fa-star text-warning"></i>
                </span>
            );
        else
            stars.push(
                <span className="ms-1" key={`ratingOfReviewInStar-${i}`}>
                    <i className="fa fa-star-o text-warning"></i>
                </span>
            );
    }

    return stars;
}

export default RatingStar;
