import React from "react";

function ProductCountHeader({displayedProducts,totalProducts}) {
    return (
        <div className="card mb-1 product-filter">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <span className="ms-1">
                        <small>Showing {displayedProducts} of {totalProducts}</small>
                    </span>

                    {/* Pending: Sort by and filter options */}
                    {/* <span>
                        <select
                        style={{minWidth:"80px"}}
                            class="form-select form-select-sm"
                            name="customerStatus" id="customerStatus">
                            <option>Newest</option>
                            <option>Price</option>
                            <option>Name</option>
                        </select>
                    </span>
                    <span className="ms-41 mx-2"><small>Filter</small></span> */}

                </div>
            </div >
        </div >
    );
}

export default ProductCountHeader;
