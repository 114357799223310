import React from 'react'

export default function NoProducts() {
    return (
        <div className="card primary-bg-color">
            <div className="single-page-wrapper card-body">
                <div className="justify-content-center">
                    <h6 className="accent-red">
                        No Products found
                    </h6>
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/bg-img/emptyCart.png"
                        }
                        alt=""
                    />
                </div>
            </div>
        </div>
    )
}
