export const BEGIN_ACTION_IN_ADDRESS_REDUCER = "BEGIN_ACTION_IN_ADDRESS_REDUCER";
export const BEGIN_ACTION_IN_CATEGORIES_REDUCER = "BEGIN_ACTION_IN_CATEGORIES_REDUCER";
export const BEGIN_ACTION_IN_HOME_REDUCER = "BEGIN_ACTION_IN_HOME_REDUCER";
export const BEGIN_ACTION_IN_PRODUCT_REDUCER = "BEGIN_ACTION_IN_PRODUCT_REDUCER";
export const BEGIN_ACTION_IN_PRODUCT_DETAILS_REDUCER = "BEGIN_ACTION_IN_PRODUCT_DETAILS_REDUCER";
export const BEGIN_ACTION_IN_OFFER_REDUCER = "BEGIN_ACTION_IN_OFFER_REDUCER";
export const BEGIN_ACTION_IN_ORDER_PROCESS_REDUCER = "BEGIN_ACTION_IN_ORDER_PROCESS_REDUCER";
export const BEGIN_ACTION_IN_ORDER_DATA_REDUCER = "BEGIN_ACTION_IN_ORDER_DATA_REDUCER";
export const BEGIN_ACTION_IN_REFUND_REDUCER = "BEGIN_ACTION_IN_REFUND_REDUCER";
export const EXCEPTION_GENERATED_IN_ADDRESS_REDUCER = "EXCEPTION_GENERATED_IN_ADDRESS_REDUCER";
export const EXCEPTION_GENERATED_IN_CATEGORIES_REDUCER = "EXCEPTION_GENERATED_IN_CATEGORIES_REDUCER";
export const EXCEPTION_GENERATED_IN_HOME_REDUCER = "EXCEPTION_GENERATED_IN_HOME_REDUCER";
export const EXCEPTION_GENERATED_IN_PRODUCT_REDUCER = "EXCEPTION_GENERATED_IN_PRODUCT_REDUCER";
export const EXCEPTION_GENERATED_IN_PRODUCT_DETAILS_REDUCER = "EXCEPTION_GENERATED_IN_PRODUCT_DETAILS_REDUCER";
export const EXCEPTION_GENERATED_IN_OFFER_REDUCER = "EXCEPTION_GENERATED_IN_OFFER_REDUCER";
export const EXCEPTION_GENERATED_IN_ORDER_PROCESS_REDUCER = "EXCEPTION_GENERATED_IN_ORDER_PROCESS_REDUCER";
export const EXCEPTION_GENERATED_IN_ORDER_DATA_REDUCER = "EXCEPTION_GENERATED_IN_ORDER_DATA_REDUCER";
export const EXCEPTION_GENERATED_IN_REFUND_REDUCER = "EXCEPTION_GENERATED_IN_REFUND_REDUCER";
export const CLEAR_EXCEPTION_AND_LOADING_STATE = "CLEAR_EXCEPTION_AND_LOADING_STATE";
export const CLEAR_DATA_IN_ADDRESS_REDUCER = "CLEAR_DATA_IN_ADDRESS_REDUCER";
export const CLEAR_DATA_IN_OFFER_REDUCER = "CLEAR_DATA_IN_OFFER_REDUCER";
export const CLEAR_DATA_IN_ORDER_PROCESS_REDUCER = "CLEAR_DATA_IN_ORDER_PROCESS_REDUCER";
export const CLEAR_DATA_IN_ORDER_DATA_REDUCER = "CLEAR_DATA_IN_ORDER_DATA_REDUCER";
export const CLEAR_DATA_IN_REFUND_REDUCER = "CLEAR_DATA_IN_REFUND_REDUCER";
export const CLEAR_PROCESSED_ORDER = "CLEAR_PROCESSED_ORDER";

export const SUCCESS_GET_STORE_CONFIGS = "SUCCESS_GET_STORE_CONFIGS";
export const SUCCESS_GET_HOMEPAGE_DATA = "SUCCESS_GET_HOMEPAGE_DATA";
export const SUCCESS_GET_CATEGORIES = "SUCCESS_GET_CATEGORIES";
export const SUCCESS_GET_PRODUCTS = "SUCCESS_GET_PRODUCTS";
export const SET_FETCHED_PRODUCTS_CATEGORY_IDS = "SET_FETCHED_PRODUCTS_CATEGORY_IDS";
export const SET_LOADED_PAGENO_OF_PRODUCTS = "SET_LOADED_PAGENO_OF_PRODUCTS";
export const UPDATE_LOADED_PAGENO_OF_PRODUCTS = "UPDATE_LOADED_PAGENO_OF_PRODUCTS";
export const SUCCESS_GET_PRODUCTS_DETAILS = "SUCCESS_GET_PRODUCTS_DETAILS";
export const SUCCESS_GET_PRODUCTS_REVIEWS = "SUCCESS_GET_PRODUCTS_REVIEWS";
export const SET_LOADED_PAGE_NUMBER_OF_PRODUCT_REVIEWS = "SET_LOADED_PAGE_NUMBER_OF_PRODUCT_REVIEWS";
export const UPDATE_LOADED_PAGE_NUMBER_OF_PRODUCT_REVIEWS = "UPDATE_LOADED_PAGE_NUMBER_OF_PRODUCT_REVIEWS";
export const IS_USER_ALREADY_POSTED_REVIEW = "IS_USER_ALREADY_POSTED_REVIEW";
export const SUCCESS_POST_RATING = "SUCCESS_POST_RATING";
export const SUCCESS_POST_USER_ADDRESS = "SUCCESS_POST_USER_ADDRESS";
export const SUCCESS_GET_USER_ADDRESSES = "SUCCESS_GET_USER_ADDRESSES";
export const REDIRECT_TO_UPDATE_ADDRESS = "REDIRECT_TO_UPDATE_ADDRESS";
export const CLEAR_ADDRESS_TO_BE_UPDATE = "CLEAR_ADDRESS_TO_BE_UPDATE";
export const SUCCESS_UPDATE_USER_ADDRESSES = "SUCCESS_UPDATE_USER_ADDRESSES";
export const SUCCESS_DELETE_USER_ADDRESSES = "SUCCESS_DELETE_USER_ADDRESSES";
export const SUCCESS_SET_DEFAULT_ADDRESS = "SUCCESS_SET_DEFAULT_ADDRESS";
export const SET_SELECTED_ADDRESS = "SET_SELECTED_ADDRESS";
export const SUCCESS_GET_OFFERS = "SUCCESS_GET_OFFERS";
export const APPLY_COUPON_CODE = "APPLY_COUPON_CODE";
export const REMOVE_APPLIED_COUPON_CODE = "REMOVE_APPLIED_COUPON_CODE";
export const SET_ORDER_DATA = "SET_ORDER_DATA";
export const SUCCESS_GET_PAYMENT_METHODS = "SUCCESS_GET_PAYMENT_METHODS";
export const SUCCESS_GET_DELIVERY_METHODS = "SUCCESS_GET_DELIVERY_METHODS";
export const SUCCESS_GET_SHIPPING_CONFIGS = "SUCCESS_GET_SHIPPING_CONFIGS";
export const SUCCESS_GET_MAP_API_KEY = "SUCCESS_GET_MAP_API_KEY";
export const SUCCESS_CALCULATE_ADDRESS_ROUTE = "SUCCESS_CALCULATE_ADDRESS_ROUTE";
export const ORDER_PROCESSED = "ORDER_PROCESSED";
export const BEGIN_PAYMENT_PROCESS = "BEGIN_PAYMENT_PROCESS";
export const BEGIN_PAYMENT_PROCESS_END = "BEGIN_PAYMENT_PROCESS_END";
export const PAYMENT_CANCELED = "PAYMENT_CANCELED";
export const ORDER_PAYMENT_FAILED = "ORDER_PAYMENT_FAILED";
export const ORDER_PLACED = "ORDER_PLACED";
export const ORDER_CENCELED = "ORDER_CENCELED";
export const SUCCESS_GET_ORDER_HISTORY = "SUCCESS_GET_ORDER_HISTORY";
export const SUCCESS_GET_ORDER_DETAILS = "SUCCESS_GET_ORDER_DETAILS";
export const CLEAR_IS_ORDER_DETAILS_FOUND = "CLEAR_IS_ORDER_DETAILS_FOUND";
export const SUCCESS_GET_ORDER_HISTORY_BY_ORDER_ID = "SUCCESS_GET_ORDER_HISTORY_BY_ORDER_ID";
export const SUCCESS_REFETCH_ORDER_DETAILS = "SUCCESS_REFETCH_ORDER_DETAILS";
export const ADD_PLACED_ORDERS_COUNT = "ADD_PLACED_ORDERS_COUNT";
export const SUCCESS_GET_REDUNDS_DATA = "SUCCESS_GET_REDUNDS_DATA";
export const ADD_CREATED_REFUND_COUNT = "ADD_CREATED_REFUND_COUNT";
export const SUCCESS_GET_LAST_REFUND_DATA = "SUCCESS_GET_LAST_REFUND_DATA";
export const SUCCESS_GET_REDUND_DETAILS_DATA = "SUCCESS_GET_REDUND_DETAILS_DATA";
export const CLEAR_ADDED_UPDATED_ADDRESS = "CLEAR_ADDED_UPDATED_ADDRESS";
