import React, { useContext } from "react";
import { serverContext } from "../../context/serverContext";
import { Link } from "react-router-dom/cjs/react-router-dom";

function Carousel({ banners }) {

    const { serverImagePath } = useContext(serverContext);

    const imageUrl = (url) => {
        return serverImagePath + url;
    };

    let bannerElements = '';
    let carouselIndicators = [];

    if (Array.isArray(banners)) {

        bannerElements = banners?.map((banner, index) => {
            return (
                <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={banner?.BannerPath}>
                    <Link to={banner?.Link || "#"}>
                        <img
                            className="d-block w-100"
                            src={imageUrl(banner?.BannerPath)}
                            alt={banner?.BannerPath}
                        /> </Link>
                </div>
            )
        });


        for (let i = 0; i < banners?.length; i++)
            carouselIndicators.push(
                <li
                    key={"indicators-" + i}
                    className={i === 0 ? "active" : ""}
                    data-bs-target="#homePageCarousel"
                    data-bs-slide-to={i}
                ></li>)

    }

    return (
        <>
            <div
                className="carousel slide"
                id="homePageCarousel"
                data-bs-ride="carousel"
            >
                {/* Carousel Indicators*/}
                <ol className="carousel-indicators">
                    {carouselIndicators}
                </ol>
                {/* Carousel Inner*/}
                <div className="carousel-inner">
                    {bannerElements}
                </div>
            </div>
        </>
    );
}

export default Carousel;
