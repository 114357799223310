import React, { useState, useContext, useEffect } from "react";
import { cartItemContext } from "../../context/cartItemContext";
import { serverContext } from "../../context/serverContext";
import ImageGallery from "react-image-gallery";
import 'react-image-gallery/styles/css/image-gallery.css';
import { sanitize } from "dompurify";
import RatingStar from "./RatingStar";

function ProductImages({ firstImage, additionalImages, imagePath }) {

    const [thumbnailPosition, setThumbnailPosition] = useState("bottom");

    useEffect(() => {
        const updateThumbnailPosition = () => {
            // Check if the screen width is 1024px or more
            setThumbnailPosition(window.matchMedia('(min-width: 992px)').matches ? "left" : "bottom");
        };

        // Set the initial position
        updateThumbnailPosition();

        // Add event listener to update position on resize
        window.addEventListener('resize', updateThumbnailPosition);
        return () => window.removeEventListener('resize', updateThumbnailPosition);
    }, []);

    // Check if the image is already present in the array
    const isImagePresent = additionalImages?.images?.some(
        (image) => image.ImageName === firstImage.ImageName
    );

    // Add the image at the beginning if it's not present
    const images = !isImagePresent
        ? [firstImage, ...(additionalImages?.images || [])]
        : additionalImages?.images || [];


    const formattedImages = images.map((image) => ({
        original: `${imagePath}/${image.ImageName}`,
        thumbnail: `${imagePath}/${image.ImageName}`,
    }));

    return <ImageGallery items={formattedImages} showPlayButton={false} thumbnailPosition={thumbnailPosition} useBrowserFullscreen={true} />

}

function ProductDescription({ description, showFull }) {

    if (!description?.length)
        return false;

    // Limit description to 500 characters if showFull not passed
    const shortDescription = description?.length > 500 && !showFull
        ? description.slice(0, 500) + '...<a class="ms-2 text-color fw-bold" href="#desciption">More</a>'
        : description;

    if (showFull && description?.length)
        return <>
            <h5 className="text-color">Details:</h5>
            <div dangerouslySetInnerHTML={{ __html: sanitize(description) }}></div>
        </>
    else
        return <div dangerouslySetInnerHTML={{ __html: sanitize(shortDescription) }}></div>
}

function ProductData({ productDetails, productImages, productConfig, totalNumberOfReviews, averageRatings }) {

    const uuid = require("uuid");
    const { serverImagePath } = useContext(serverContext);
    const { productsInCart, addProductsToCart, updateProductQuantityInCart } = useContext(cartItemContext);

    const imagePath = serverImagePath + "/products/" + productDetails?.ProductId;

    //first product image object to show in image gallery
    const firstImage = { "ImageName": productDetails.ProductImage };

    const addedProductToCart = productsInCart.filter(
        item => parseInt(item.ProductId) === parseInt(productDetails.ProductId)
    );

    //Priority 1: set selected config if product is added to cart and has config
    //Priority 2: set first option as selected if has config
    //Priority 3: set manual config with default price and null
    const [selectedConfig, setSelectedConfig] = useState(
        (addedProductToCart?.length && { "Value": addedProductToCart[0]?.PackSize, "Price": addedProductToCart[0]?.ProductPrice }) ||
        (parseInt(productDetails.PackType) === 1 && productConfig?.configs[0]) ||
        { "Value": null, "Price": productDetails.ProductPrice }
    );

    //handle product config
    const handleConfigSelection = (value, price) => {

        setSelectedConfig(prevSizes => ({
            ...prevSizes,
            "Value": value,
            "Price": price
        }));
    };

    //step 1: show add to cart if not added
    //step 2: show qty btns if added
    //step 3: if product have options then show qty based on selected options
    const RenderAddCartActions = ({ product }) => {

        if (parseInt(product.InStock) === 0)
            return <button className=" btn product-page theme-btn btn-sm disabled">
                Out of Stock
            </button>


        const addedProductToCart = productsInCart.filter(
            item => parseInt(item.ProductId) === parseInt(product.ProductId)
        );

        const selectedProductConfig = selectedConfig

        //check if product is added to cart
        if (addedProductToCart?.length > 0) {

            //check if product with selected size is added
            const productWithSelectedSize = addedProductToCart.find(item => {
                return item.PackSize === selectedProductConfig?.Value;
            });

            //get the cart product qty
            const addedQty = productWithSelectedSize?.Qty || 1;

            if (productWithSelectedSize || !selectedProductConfig) {
                return <div className="product-page-quantity mb-3">
                    <label className="text-color qty-label mx-1">Quantity:</label>
                    <button className="product-qty-decrement-button" onClick={() => updateProductQuantityInCart(productWithSelectedSize?.ItemId, (addedQty - 1), selectedConfig?.Value || productWithSelectedSize?.PackSize)}>
                        <i className="fa fa-minus"></i>
                    </button>
                    <input className="product-qty-text" type="text" disabled value={addedQty} />
                    <button className="product-qty-increment-button" onClick={() => updateProductQuantityInCart(productWithSelectedSize?.ItemId, (addedQty + 1), selectedConfig?.Value || productWithSelectedSize?.PackSize)}>
                        <i className="fa fa-plus"></i>
                    </button>
                </div>
            }
        }

        //add to cart product action object
        const productCartObject = {
            ItemId: uuid.v4(),
            ProductId: product.ProductId,
            ProductImage: `${imagePath}/${product.ProductImage}`,
            ProductName:
                product.ProductName,
            ProductPrice: selectedConfig?.Price || product.ProductPrice,
            PackSize:
                parseInt(
                    product.PackType
                ) === 1
                    ? selectedConfig?.Value
                    : null,
            Qty: 1,
        }

        return <button className="product-page theme-btn btn-sm" onClick={() => {
            addProductsToCart(productCartObject);
        }}>Add to Cart</button>
    }

    return (
        productDetails && (
            <>
                <div className="card">
                    <div className="card-body py-1 row">
                        <div className="col-sm-6">
                            <ProductImages firstImage={firstImage} additionalImages={productImages} imagePath={imagePath} />
                        </div>
                        <div className="col-sm-6 mt-5">
                            <h1>{productDetails.ProductName}</h1>
                            {parseInt(productDetails.InStock) === 0 ? <span className="badge bg-danger">Out of stock</span> : <span className="badge bg-success">In Stock</span>}


                            {/* component to Print stars from given Ratings */}
                            <div className="mb-1 mt-2">
                                <a href="#reviews" className="text-color"><RatingStar Ratings={averageRatings} /> {totalNumberOfReviews > 0 && <span className="ms-2">{totalNumberOfReviews} Reviews</span>} </a>
                            </div>
                            <h3>₹ {selectedConfig?.Price || productDetails?.ProductPrice}</h3>
                            {
                                parseInt(productDetails?.PackType) === 1 && <>
                                    <div className="text-color config-label">{productConfig?.typeName}</div>
                                    <div className="config-options">
                                        {productConfig?.configs.map((config) => {
                                            return (<div
                                                key={`product-config-${productDetails?.ProductId}-${config.Value}`}
                                                className={`config-option ${selectedConfig?.Value === config.Value ? 'selected' : ''}`}
                                                onClick={() => handleConfigSelection(config.Value, config.Price)}
                                            >
                                                {config.Value}
                                            </div>)
                                        }
                                        )}
                                    </div>
                                </>
                            }
                            <RenderAddCartActions product={productDetails} />
                        </div>

                        <div className="col-12 py-3" id="desciption">
                            <ProductDescription description={productDetails.Description} showFull={true} />
                        </div>
                    </div>
                </div>
            </>
        )
    );
}

export default ProductData;
