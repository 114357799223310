import React from 'react'

export default function OrderStatusDetails({ orderNumber, orderDate, status, shippingType, addressType }) {

    switch (status) {
        case '1':
            status = <span className="badge bg-danger">Pending</span>;
            break;

        case '2':
            status = <span className="badge bg-info">Processing</span>;
            break;

        case '3':
            status = <span className="badge bg-primary">Ready</span>;
            break;

        case '4':
            status = <span className="badge bg-success">Completed</span>;
            break;

        case '0':
            status = <span className="badge bg-danger">Canceled</span>;
            break;

        case '-1':
            status = <span className="badge bg-danger">Canceled</span>;
            break;

        default:
            status = <span className="badge bg-danger">Unknown</span>;
    }

    return (
        <div className='card'>
            <div className='card-header'>
                Order Details
            </div>
            <div className='p-3 pb-0'>


                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <th>Order Number</th>
                            <td>{orderNumber}</td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td>{status}</td>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <td>{orderDate}</td>
                        </tr>
                        <tr>
                            <th>Shipping Type</th>
                            <td>{shippingType === 'Shipping' ? `Deliver to ${addressType}` : shippingType}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    )
}
