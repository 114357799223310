import { React, useEffect, useState, useLayoutEffect } from 'react';
import ShowMoreText from 'react-show-more-text';
const inline_block_child = {
    display: "inline-block"
}

const addressIconStyle = {
    display: "inline-block",
    position: "relative",
    top: "5px"
}

export default function AddressListItems({ addressList, selectedAddress, handleUpdateAddress, confirmDeleteAddress, setDefaultCallback = false, allowSetDefaultAction = false }) {
    //set text width as 55% of screen size in show more text component
    const [textWidthInShowMoreText, setTextWidthInShowMoreText] = useState((window.innerWidth / 100) * 55);

    let addressItems = null;

    const [isActive, setIsActive] = useState(parseInt(selectedAddress.AddressId));

    useEffect(() => {
        //set global variable that we used in footer component to set selected address
        window.selectedAddress = selectedAddress;
        //eslint-disable-next-line
    }, [])


    //handle selected address
    const handleSelectedAddressChange = (address) => {
        setIsActive(address.AddressId)

        //set global variable that we used in footer component to set selected address
        window.selectedAddress = address;
    }

    //update text width in ShowMoreText component when clients window resize
    useLayoutEffect(() => {
        function updateSize() {
            setTextWidthInShowMoreText((window.innerWidth / 100) * 55);
        }
        //event listnet for screen resize
        window.addEventListener('resize', updateSize);
        updateSize();

        //clear window resize event listner
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    if (Array.isArray(addressList) && addressList?.length > 0) {
        addressItems = addressList.map((item) => {
            return (
                <div className='col-sm-6' key={`address-item-${item.AddressId}`}>
                    <div className={`card address-card mb-1 ${parseInt(isActive) === parseInt(item.AddressId) && !allowSetDefaultAction ? 'active' : ''} `}>
                        <label htmlFor={`address-${item.AddressId}`} style={{ cursor: 'pointer' }}>
                            <div className='card-body pb-2'>
                                <>
                                    <span className='custom-span'><i className='bi bi-geo-alt'></i>{item.Type}</span>

                                    {allowSetDefaultAction && !item?.IsDefault && selectedAddress.AddressId === item.AddressId && <b className='badge rounded-pill custom-btn-style float-end mx-3'>Selected for order</b>}
                                    {parseInt(item?.IsDefault) === 1 && <b className='badge rounded-pill custom-btn-style float-end mx-3'>Default</b>}

                                    <div className='mt-1 d-flex align-item-start'>
                                        <div style={addressIconStyle} >
                                            {
                                                item.Type === 'Home' ?
                                                    <i className='bi bi-house border custom-border ps-3 px-3 p-2' style={{ fontSize: "1.3rem" }}></i> :
                                                    item.Type === 'Office' ?
                                                        <i className='bi bi-building border custom-border ps-3 px-3 p-2' style={{ fontSize: "1.3rem" }}></i> :
                                                        <i className='bi bi-geo border custom-border ps-3 px-3 p-2' style={{ fontSize: "1.3rem" }}></i>
                                            }
                                        </div>

                                        <div style={inline_block_child} className='ms-3'>
                                            <ShowMoreText
                                                lines={1}
                                                more={""}
                                                less={""}
                                                className="tuncatedOfferDetails"
                                                expanded={false}
                                                truncatedEndingComponent={"... "}
                                                width={300}

                                            >
                                                {item.FullAddress}
                                            </ShowMoreText>
                                            <ShowMoreText
                                                lines={1}
                                                more={""}
                                                less={""}
                                                className="tuncatedOfferDetails"
                                                anchorClass="text"
                                                expanded={false}
                                                truncatedEndingComponent={"... "}
                                                width={textWidthInShowMoreText}
                                            >
                                                {item.HouseFlatBlockNumber}
                                            </ShowMoreText>
                                            <ShowMoreText
                                                lines={1}
                                                more={""}
                                                less={""}
                                                className="tuncatedOfferDetails"
                                                anchorClass="text"
                                                expanded={false}
                                                truncatedEndingComponent={"... "}
                                                width={textWidthInShowMoreText}
                                            >
                                                {item.Landmark}
                                            </ShowMoreText>
                                        </div>

                                        <div className='float-end d-none'>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input custom-form-check"
                                                    type="radio"
                                                    name="address"
                                                    id={`address-${item.AddressId}`}
                                                    value={`address-${item.AddressId}`}
                                                    onChange={() => handleSelectedAddressChange(item)}
                                                    defaultChecked={parseInt(selectedAddress.AddressId) === parseInt(item.AddressId) ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-4 fw-bold'>
                                        <hr />
                                        <span className='' onClick={() => handleUpdateAddress(item.AddressId)}>Edit</span>
                                        {selectedAddress.AddressId !== item.AddressId && !item?.IsDefault && <span className="ms-3" onClick={() => confirmDeleteAddress(item.AddressId, item.FullAddress)}>Delete</span>}
                                        {allowSetDefaultAction && !item?.IsDefault && <span className="ms-3" onClick={() => setDefaultCallback(item.AddressId)}>Set Default</span>}
                                    </div>

                                </>
                            </div>
                        </label >
                    </div>
                </div>
            )
        });
    }

    return addressItems;
}
