import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { authContext } from '../../context/authContext';
import { modalContext } from '../../context/modalContext';
import { serverContext } from '../../context/serverContext';
import { clearAddedUpdatedAddressFromReducer, deleteAddress, getUserAddress, redirectToUpdateAddress } from '../../redux/actions/addressActions';
import { decrypt, isDecryptedDataValid } from '../functions/cipherFunctions';
import AddressListSkeleton from './ListSkeleton';
import ConfirmDeleteAddressDialog from './ConfirmDeleteAddressDialog';
import AddressListItems from './AddressListItems';
import NoAddress from './NoAddress';
import { setSelectedAddress } from '../../redux/actions/orderProcessActions';

export default function AddressList() {

    const { isParamPassed } = useParams();

    const dispatch = useDispatch();
    const history = useHistory();
    const { serverPath } = useContext(serverContext);
    const { isAuthenticate } = useContext(authContext);
    const { showLoading, hideLoading, showSuccessDialog, showErrorDialog } = useContext(modalContext);

    const [addressToBeDelete, setAddressToBeDelete] = useState(null);
    const isAddressDeleted = useRef(false);

    const loading = useSelector(state => state.addressReducer.loading);
    const exception = useSelector(state => state.addressReducer.exception);

    //get new added address from reducer
    const newlyAddedAddress = useSelector(state => state.addressReducer.newAddress);
    //get new added address from reducer
    const updatedAddress = useSelector(state => state.addressReducer.updatedAddress);

    //get and decrypt address list from reducer
    const addressListFromReducer = useSelector(state => state.addressReducer.addressList);
    const addressList = addressListFromReducer ? isDecryptedDataValid(decrypt(addressListFromReducer, true)) : [];

    //get and decrypt selected address
    const getSelectedAddressFromReducer = useSelector(state => state.orderProcessReducer.selectedAddress);
    const selectedAddress = getSelectedAddressFromReducer && isDecryptedDataValid(decrypt(getSelectedAddressFromReducer, true));

    //if redirected to this component after adding/updating address then set added/updated address as selcetd address 
    //and redirect users to cart page
    useEffect(() => {
        if (isParamPassed === "newAddressAdded" && newlyAddedAddress) {
            dispatch(setSelectedAddress(newlyAddedAddress));
            dispatch(clearAddedUpdatedAddressFromReducer());
            history.push("/cart");
        } else if (isParamPassed === "updatedAddress" && updatedAddress && updatedAddress?.AddressId === selectedAddress?.AddressId) {
            dispatch(setSelectedAddress(updatedAddress));
            dispatch(clearAddedUpdatedAddressFromReducer());
            history.push("/cart");
        } else return false;
        //eslint-disable-next-line
    }, [])

    //scroll to top when component loads 
    useEffect(() => {

        !selectedAddress.AddressId && window.scrollTo(0, 0);
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        /* 
        *   redirect to login page if user is not logged in yet
        */
        //we are fetching logged in users data from authContext and it takes few seconds to authenticate user each time when page reloads
        //here we check "isAuthenticate === false" because the default state of isAuhtenticate is as null
        //if user is not logged in then state of isAuthenticate will be false else state will store users data
        if (isAuthenticate === false)
            history.push('/login');

        //dispatch action to fetch address list from server if already not fetched
        if (addressList.length < 1 && isAuthenticate?.userId)
            dispatch(getUserAddress(serverPath, isAuthenticate?.userId));
        //eslint-disable-next-line
    }, [isAuthenticate]);


    //method to show confirm dialog to delete address
    const confirmDeleteAddress = (addressId, fullAddress) => {
        //if user is offline then show error warning
        if (!window.navigator.onLine) {
            showErrorDialog(
                "You are offline",
                "Please check your internet connection and try again"
            );
            return false;
        }

        //return if user try to delete selected address
        if (addressId === selectedAddress.AddressId) {
            showErrorDialog("Can't delete selected address", "If you want to delete this address than please add/select another address for your orders")
            return;
        }

        setAddressToBeDelete({ addressId: addressId, fullAddress: fullAddress });

        window.$("#confirmDeleteDialog").modal("show");
    }

    //handle delete address
    const handleDeleteAddress = (addressId) => {

        //clear global selected address vavriable if it is same as address to be delete
        if (window.selectedAddress.AddressId === addressId)
            window.selectedAddress = null

        isAddressDeleted.current = true;
        showLoading();
        //dispatch action to delete address
        dispatch(deleteAddress(serverPath, addressId, addressList));

        showSuccessDialog('Address Deleted', addressToBeDelete.fullAddress)
    }

    //handle update address
    const handleUpdateAddress = (addressId) => {
        //set id of address to be update and redirect
        dispatch(redirectToUpdateAddress(addressId));
        history.push("/address/update/cart");
    }

    if (exception) {
        hideLoading();
        showErrorDialog(
            exception.message,
            exception.description
        );
        dispatch({
            type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
        });
    }

    //hide loading when address deleted successfully
    if (!loading && !exception && isAddressDeleted.current === true) {
        hideLoading();
        isAddressDeleted.current = false;
    }

    return (
        <>
            <ConfirmDeleteAddressDialog message={"Want to delete this address?"} addressToBeDelete={addressToBeDelete} handleDeleteAddress={handleDeleteAddress} />
            <div className="page-content-wrapper">
                <div className="row justify-content-center">
                    {
                        loading && !isAddressDeleted.current ?
                            <AddressListSkeleton /> : (addressList?.length ?
                                <AddressListItems addressList={addressList} selectedAddress={selectedAddress} handleUpdateAddress={handleUpdateAddress} confirmDeleteAddress={confirmDeleteAddress} showSetDefaultButton={true} /> :
                                <NoAddress message={'We are waiting for your address.'} description={'Let us pack something special for you.'} />
                            )
                    }
                </div>
            </div>
        </>
    );
}
