import React from 'react'

export default function ShippingAddress({ address }) {
    return (
        <div className='card mt-1'>
            <div className='card-header'>
                Shipping Address
            </div>
            <div className='p-3 pb-0'>

                <p className="lh-base">
                    <span>
                        {
                            address?.type === "Home" ?
                                <i className="bi bi-house" style={{ "fontSize": "1.3rem" }}></i> :
                                address?.type === "Office" ?
                                    <i className="bi bi-building" style={{ "fontSize": "1.3rem" }}></i>
                                    :
                                    <i className="bi bi-geo" style={{ "fontSize": "1.3rem" }}></i>
                        }

                        <b className='ms-1'>{address?.Type}</b>
                    </span><br />

                    <span><b>{address?.Name}</b></span> <br />
                    <span>{address?.FullAddress}</span><br />
                    <span>{address?.HouseFlatBlockNumber}</span><br />
                    <span>{address?.Landmark}</span><br />
                    <span className="text-muted">{address?.PhoneNumber}</span><br />
                    {address?.Email &&
                        <span className="text-muted">{address?.Email}</span>}

                </p>
            </div>
        </div >
    )
}