import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function AddressListSkeleton() {

    return (
        <div className='container-fluid'>
            <SkeletonTheme highlightColor="#bababa">
                <div className='row'>
                    <div className='col-sm-6'>
                        <Skeleton height={180} count={6} />
                    </div>
                    <div className='col-sm-6'>
                        <Skeleton height={180} count={6} />
                    </div>
                </div>
            </SkeletonTheme>
        </div>
    )
}
