import React, { useContext, useEffect, useRef } from 'react'
import OrderItems from '../orderDetails/OrderItems'
import { useDispatch, useSelector } from 'react-redux';
import { decrypt, isDecryptedDataValid } from '../functions/cipherFunctions';
import { serverContext } from '../../context/serverContext';
import ShippingAddress from '../orderDetails/ShippingAddress';
import OrderAmount from '../orderDetails/OrderAmount';
import OrderProcess from '../checkout/OrderProcess';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { clearOrderProcessReducersData, clearProcessedOrder, paymentCanceled, paymentProcess, paymentProcessEnd, updateFailedPaymentStatus, validatePayment } from '../../redux/actions/orderProcessActions';
import { authContext } from '../../context/authContext';
import { addPlacedOrdersCount, getOrderHistoryByOrderId } from '../../redux/actions/orderDataActions';
import { modalContext } from '../../context/modalContext';
import { clearOfferReducersData } from '../../redux/actions/offerActions';

export default function CheckoutOrder() {


    const { serverPath, serverImagePath } = useContext(serverContext);
    const { isAuthenticate } = useContext(authContext);
    const { showErrorDialog } = useContext(modalContext);

    const dispatch = useDispatch();
    const history = useHistory();


    //get and decrypt shipping configurations from reducer
    const getStoreConfigsData = useSelector(state => state.homeReducer.storeConfigs);
    const storeConfigs = getStoreConfigsData && isDecryptedDataValid(decrypt(getStoreConfigsData, true));

    //get and decrypt order history data from reducer
    const getOrderHistoryData = useSelector(state => state.orderDataReducer.orderHistoryData);
    const orderHistoryList = getOrderHistoryData ? isDecryptedDataValid(decrypt(getOrderHistoryData, true)) : null;

    const placedOrder = useSelector((state) => state.orderProcessReducer.placedOrder);
    const countPlacedOrders = useSelector(state => state.orderDataReducer.countPlacedOrders);

    //get data about order processes from reducer
    const processedOrder = useSelector((state) => state.orderProcessReducer.processedOrder);
    const createdOrder = processedOrder?.createdOrder;
    
    const processedOrderData = processedOrder?.orderData ? isDecryptedDataValid(decrypt(processedOrder.orderData, true)) : null;


    const isPaymentProcessing = useSelector((state) => state.orderProcessReducer.paymentProcessing);
    const isPaymentCanceled = useSelector((state) => state.orderProcessReducer.paymentCanceled);
    const isPaymentFailed = useSelector((state) => state.orderProcessReducer.paymentFailed);
    const exception = useSelector((state) => state.orderProcessReducer.exception);

    //set references variables to check order status
    const paymentFailed = useRef(false);
    const isNewOrderHistoryFetched = useRef(false);
    const isOrderPlacedSuccess = useRef(false);


    // // redirect to cart page if cart data is not proccessed
    useEffect(() => {
        if (!createdOrder?.orderId)
            history.replace('/cart');

        //eslint-disable-next-line
    }, [])


    //function to invoke razorpay popup for payment process
    const invokeRazorpay = (isRevise = false) => {

        //Invoke Razorpay if current processed order has Razorpay payment method selected
        if (createdOrder?.keyId && createdOrder?.amount && createdOrder?.razorpayOrderId) {
            const options = {
                "key": createdOrder?.keyId,
                "amount": createdOrder?.amount,
                "currency": "INR",
                "order_id": createdOrder?.razorpayOrderId,
                "name": storeConfigs?.storeName,
                "description": "Thanks for your purchase",
                "image": storeConfigs?.storeLogo,
                //handler for if payment successfully processed
                "handler": function (response) {

                    //current order id
                    response.orderId = createdOrder?.orderId;

                    //dispatch action to validate payment
                    dispatch(validatePayment(serverPath, response));
                },
                "prefill": {
                    "name": isAuthenticate?.username,
                    "email": isAuthenticate?.email || processedOrderData?.orderAddress?.Email || '',
                    "contact": isAuthenticate?.phoneNumber || processedOrderData?.orderAddress?.PhoneNumber
                },
                "theme": {
                    "color": localStorage.getItem('theme') === 'dark' ? "#061238" : '#06227a'
                },
                "modal": {
                    //event listner to detect when customer close payment pop-up
                    "ondismiss": function () {
                        console.log('Payment pop-up closed');

                        if (paymentFailed.current !== true)
                            dispatch(paymentCanceled());
                        else
                            dispatch(paymentProcessEnd(paymentFailed.current));


                        //fetch current orders history data if orderHistoryList if not null
                        if (isNewOrderHistoryFetched.current === false && createdOrder?.orderId && orderHistoryList?.length) {
                            dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, createdOrder?.orderId, orderHistoryList));
                            dispatch(addPlacedOrdersCount(countPlacedOrders + 1));
                        }

                        isNewOrderHistoryFetched.current = true;
                    }
                }
            };

            if (isRevise)
                dispatch(paymentProcess());

            const rzp = new window.Razorpay(options);
            rzp.open(options);


            rzp.on('payment.failed', function (response) {
                const params = {
                    orderId: createdOrder?.orderId,
                    status: "Failed",
                    rzpOrderId: response.error?.metadata?.order_id,
                    rzpPaymentId: response.error?.metadata?.payment_id
                }

                paymentFailed.current = true;

                //dispatch action to update payment status as failed for current order
                dispatch(updateFailedPaymentStatus(serverPath, params));

                //fetch current orders history data if orderHistoryList if not null
                if (isNewOrderHistoryFetched.current === false && createdOrder?.orderId && orderHistoryList?.length) {
                    dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, createdOrder?.orderId, orderHistoryList));
                    dispatch(addPlacedOrdersCount(countPlacedOrders + 1));
                }
                else if (createdOrder && orderHistoryList?.length) {
                    setTimeout(() => {
                        const reFetch = true;
                        dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, createdOrder?.orderId, orderHistoryList, reFetch));
                    }, 1000);
                }
                isNewOrderHistoryFetched.current = true;
            });

        }
        else {
            showErrorDialog(
                'Internal server error',
                'Something went wrong, Please try again after some time'
            );
        }

    }

    //Monitor processedOrder to Invoke razorpay popup for payment when order created successfully in server
    useEffect(() => {
        if (createdOrder && processedOrderData?.paymentType !== "Pay on delivery" && (!isPaymentCanceled && !isPaymentFailed)) {
            window.$("#paymentProcessingDialog").modal("hide");
            invokeRazorpay();
        }

        if (createdOrder) {
            //clear offer and order data from reducers
            dispatch(clearOfferReducersData());
        }

        //clear order process data when user leave the checkout page after 
        //payment process initiated or payment failed/canceled
        return () => {
            
            if ((isPaymentProcessing || isPaymentCanceled || isPaymentFailed) && !isOrderPlacedSuccess.current) {

                window.cartProccessed = false;
                dispatch(clearOrderProcessReducersData());
                console.log("checkout page")
                dispatch(clearProcessedOrder());
            }
        }

        //eslint-disable-next-line
    }, [processedOrder])

    //Monitor placedOrder to redirect users to success page if payment is captured and order completed
    useEffect(() => {
        if (placedOrder?.Success) {

            isOrderPlacedSuccess.current = true;

            history.push('/order/confirmed');

            //fetch current orders history data if orderHistoryList is not null
            if (isNewOrderHistoryFetched.current === false && createdOrder && orderHistoryList?.length) {
                dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, createdOrder?.orderId, orderHistoryList));
                dispatch(addPlacedOrdersCount(countPlacedOrders + 1));
            }
            else if (createdOrder && orderHistoryList?.length) {
                setTimeout(() => {
                    const reFetch = true;
                    dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, createdOrder?.orderId, orderHistoryList, reFetch));
                }, 1000);
            }

            dispatch(clearOrderProcessReducersData());

            //set globar cart processed variable
            window.cartProccessed = false;
        }
        //eslint-disable-next-line
    }, [placedOrder]);


    //Redirect users to success page when order completed with Pay on delivery payment type 
    if (!exception && placedOrder?.Success && createdOrder?.orderId && processedOrderData?.paymentType === "Pay on delivery") {

        history.push('/order/confirmed');

        //fetch current orders history data if orderHistoryList is not null
        if (isNewOrderHistoryFetched.current === false && createdOrder && orderHistoryList?.length) {
            dispatch(getOrderHistoryByOrderId(serverPath, isAuthenticate?.userId, createdOrder?.orderId, orderHistoryList));
            dispatch(addPlacedOrdersCount(countPlacedOrders + 1));
        }

        dispatch(clearOrderProcessReducersData());

        //set globar cart processed variable
        window.cartProccessed = false;
    }


    //Show error dialog for any excetion generated
    if (exception) {
        window.$("#cancelationPolicyModal").modal("hide");
        window.$("#paymentProcessingDialog").modal("hide");

        showErrorDialog(
            exception.message,
            exception.description
        );
        dispatch({
            type: "CLEAR_EXCEPTION_AND_LOADING_STATE",
        });
    }



    return (
        <>
            <div className="page-content-wrapper">
                <div className="row">
                    <div className='col-sm-6 hide-in-mobile'>
                        <OrderItems items={processedOrderData?.orderProducts} serverImagePath={serverImagePath} />
                        <ShippingAddress address={processedOrderData?.orderAddress} />
                    </div>
                    <div className='col-sm-6'>
                        <OrderProcess paymentProcessing={isPaymentProcessing} paymentFailed={isPaymentFailed} paymentCanceled={isPaymentCanceled} revisePayment={invokeRazorpay} createdOrderNumber={createdOrder?.orderNumber} />
                        <OrderAmount subTotal={processedOrderData?.orderAmount?.subTotal} discount={processedOrderData?.orderAmount?.discount} deliveryCharge={processedOrderData?.orderAmount?.deliveryCharge} taxAndCharges={processedOrderData?.orderAmount?.taxAndCharge} totalPayableAmount={processedOrderData?.orderAmount?.total} testOrderAmount={processedOrderData?.orderAmount?.testOrderAmount} />
                    </div>
                </div>
            </div>
        </>
    )
}
