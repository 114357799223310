import React from 'react'

export default function OrderStatusHistory({ orderStatusHistory }) {


    let statusList = <h6 className='text-danger text-center p-3'>No history found</h6>;

    const getStatusName = (status) => {

        let statusName = '';
        switch (status) {
            case '1':
                statusName = 'Pending';
                break;

            case '2':
                statusName = 'Processing';
                break;

            case '3':
                statusName = 'Ready';
                break;

            case '4':
                statusName = 'Completed';
                break;

            case '0':
                statusName = 'Canceled';
                break;

            case '-1':
                statusName = 'Canceled';
                break;

            default:
                statusName = 'Unknown';
        }

        return statusName;
    }

    if (orderStatusHistory?.length > 0)
        statusList = orderStatusHistory.map(item => {

            return (
                <li key={item.RowId}>
                    <div className='item'>
                        <div className='in'>
                            <small>{item.CreatedAt}</small>
                            <small><b>{getStatusName(item.Status)}</b></small>
                        </div>
                    </div>
                    <div className='ps-3'>
                        <p className='pb-2' style={{ color: 'rgb(132, 128, 174)' }}>{item.Comment}</p>
                    </div>
                </li>
            )
        })


    return (
        <div className='card mt-1'>
            <div className='card-header'>Order History</div>
            <ul className='listview flush transparent image-listview text'>
                {statusList}
            </ul>
        </div>
    )
}
