import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export default function OrderSkeleton({ loadMore }) {
    return (
        <SkeletonTheme highlightColor="#bababa">
            <div className='m-1 mb-2 row'>
                {loadMore ?
                    <>
                        <div className='col-sm-6'>
                            <Skeleton count={1} width={'100%'} height={160} className="mb-3" />
                        </div>
                        <div className='col-sm-6'>
                            <Skeleton count={1} width={'100%'} height={160} className="mb-3" />
                        </div>
                    </>
                     :
                    <>
                        <div className='col-sm-6'>
                            <Skeleton count={4} width={'100%'} height={160} className="mb-3" />
                        </div>
                        <div className='col-sm-6'>
                            <Skeleton count={4} width={'100%'} height={160} className="mb-3" />
                        </div>
                    </>}
            </div>

        </SkeletonTheme>
    )
}
