import React from 'react'
import { Link } from 'react-router-dom'

export default function EmptyCart() {
    return (
        <div className="card primary-bg-color">
            <div className="single-page-wrapper card-body">
                <div className="justify-content-center">
                    <img
                        className="illustrate "
                        src={
                            process.env.PUBLIC_URL +
                            "/bg-img/empty-cart.png"
                        }
                        alt=""
                    />

                    <h6 className="text-color mb-5">
                    An empty cart is the only thing worse than running out of chicken. Fill it up with our best cuts!
                    </h6>

                    <Link
                        className="btn btn-warning w-50"
                        to="/"
                    >
                        Shop now <i className="fa fa-shopping-cart"></i>
                    </Link>
                </div>
            </div>
        </div>
    )
}
