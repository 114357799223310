import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function HomeSkeleton() {
    const CarouselSkeleton = () => <Skeleton style={{ height: "40vh" }} />;

    const CategorySkeleton = () => {
        let skeleton = []

        for (let i = 0; i < 4; i++) {
            skeleton.push(
                <div className="col-3" key={`featuredCategory-skeleton-${i}`}>
                    <Skeleton circle={true} height={50} width={50} />
                </div>
            );
        }
        return (
            <>
                <div className="py-4 container-fluid">
                    <Skeleton width={150} height={30} />
                    <div className="row g-3 py-2">{skeleton}</div>
                </div>
            </>
        );
    };

    const ProductSkeleton = () => {

        let skeleton = [];

        for (let i = 0; i < 12; i++) {
            skeleton.push(

                <div className="col-6 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center" key={`topRatedProducts-skeleton-${i}`}>
                    <div className="product-card p-1">
                        <Skeleton height={200} />
                        <div className="product-info">
                            <Skeleton height={20} count={3} className="p-2" />
                            <Skeleton height={40} className="mt-4" />
                        </div>
                    </div>
                </div>
            );
        }


        return (
            <>

                <div className="container-fluid">
                    <Skeleton width={150} height={30} />
                    <div className="row g-3 py-4">{skeleton}</div>
                </div>
            </>
        );
    };

    return (
        <>
            <SkeletonTheme highlightColor="#bababa">
                <CarouselSkeleton />
                <CategorySkeleton />
                <ProductSkeleton />
            </SkeletonTheme>
        </>
    );
}

export default HomeSkeleton;
