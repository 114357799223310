import React from 'react'
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom'

export default function NotFound() {

    const history = useHistory();
    const path = useLocation().pathname;

    const redirectAction = () => {
        if (path === "/not-found/home")
            history.push("/")
        else
            history.goBack()
    }

    //scroll to top when component loads 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="card primary-bg-color">
            <div className="single-page-wrapper card-body">
                <div className="justify-content-center">
                    <img
                        className="illustrate"
                        src={
                            process.env.PUBLIC_URL +
                            "/bg-img/404-illustrate.png"
                        }
                        alt=""
                    />

                    <h4 className="text-color m-3">OOPS... <br />Page not found!</h4>
                    <span className='text-color'>Even our chicken's map couldn't save this page!</span> <br />
                    <button
                        className="btn btn-creative btn-warning w-75 mt-3"
                        onClick={redirectAction}
                    >
                        <i className="fa fa-arrow-left"></i> {path === "/no-products" ? 'Back to home' : 'Take me back'}
                    </button>
                </div>
            </div>
        </div>

    )
}
