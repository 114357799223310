import {
    BEGIN_ACTION_IN_HOME_REDUCER,
    SUCCESS_GET_HOMEPAGE_DATA,
    EXCEPTION_GENERATED_IN_HOME_REDUCER,
    SUCCESS_GET_STORE_CONFIGS
} from "../constants";
import axios from "axios";
import { encrypt, hasJsonStructure } from "../../components/functions/cipherFunctions";


export function getStoreConfigs(serverPath) {
    return (dispatch) => {
        //begin fretch request
        dispatch({
            type: BEGIN_ACTION_IN_HOME_REDUCER
        });

        axios
            .get(serverPath + "/get-store-configs")
            .then((res) => {
                dispatch({
                    type: SUCCESS_GET_STORE_CONFIGS,
                    payload: encrypt(res.data, true),
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_HOME_REDUCER,
                    payload: {
                        message: message,
                        description:
                            "Something went wrong, Please try again after some time",
                    },
                });
            });
    };
}

export function getHomePageData(serverPath) {
    return (dispatch) => {
        //begin fetch data from api
        dispatch({
            type: BEGIN_ACTION_IN_HOME_REDUCER
        });

        axios
            .get(serverPath + "/home-data")
            .then((res) => {
                dispatch({
                    type: SUCCESS_GET_HOMEPAGE_DATA,
                    payload: encrypt(res.data, true)
                });
            })
            .catch((error) => {
                let message = "Internal server error";
                if (error.request) {
                    const errorText = hasJsonStructure(error.request.response) ? JSON.parse(error.request.response) : ''
                    message = errorText.Message ? errorText.Message : error.message;
                } else message = error.message;

                dispatch({
                    type: EXCEPTION_GENERATED_IN_HOME_REDUCER,
                    payload: {
                        message: message,
                        description:
                            "Something went wrong, Please try again after some time",
                    }
                });
            });
    };
}
