import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function OrderDetailsSkeleton() {
    return (
        <SkeletonTheme highlightColor="#bababa">
            <div className='row'>
                <div className='col-sm-6'>
                    <Skeleton height={150} count={6} className='mb-1' />
                </div>
                <div className='col-sm-6'>
                    <Skeleton height={150} count={6} className='mb-1' />
                </div>
            </div>
        </SkeletonTheme>
    )
}
