import {
    BEGIN_ACTION_IN_ORDER_PROCESS_REDUCER,
    SET_ORDER_DATA,
    SET_SELECTED_ADDRESS,
    SUCCESS_CALCULATE_ADDRESS_ROUTE,
    ORDER_PROCESSED,
    ORDER_PLACED,
    BEGIN_PAYMENT_PROCESS,
    PAYMENT_CANCELED,
    ORDER_PAYMENT_FAILED,
    EXCEPTION_GENERATED_IN_ORDER_PROCESS_REDUCER,
    CLEAR_EXCEPTION_AND_LOADING_STATE,
    CLEAR_DATA_IN_ORDER_PROCESS_REDUCER,
    CLEAR_PROCESSED_ORDER,
    ORDER_CENCELED,
    BEGIN_PAYMENT_PROCESS_END
} from "../constants";

const initialState = {
    loading: false,
    exception: false,
    orderData: null,
    selectedAddress: false,
    processedOrder: null,
    paymentProcessing: false,
    paymentCanceled: false,
    paymentFailed: false,
    placedOrder: false,
    orderCanceled: false
};

const orderProcessReducer = (state = initialState, action) => {
    switch (action.type) {
        case BEGIN_ACTION_IN_ORDER_PROCESS_REDUCER:
            return {
                ...state,
                loading: true,
                exception: false,
            };

        case BEGIN_PAYMENT_PROCESS:
            return {
                ...state,
                paymentProcessing: true,
                paymentCanceled: false,
                paymentFailed: false
            };

        case BEGIN_PAYMENT_PROCESS_END:
            return {
                ...state,
                paymentProcessing: false,
                paymentCanceled: action.payload.isPaymentFailed ? false : true,
                paymentFailed: action.payload.isPaymentFailed ? true : false
            };

        case PAYMENT_CANCELED:
            return {
                ...state,
                paymentProcessing: false,
                paymentCanceled: true,
                paymentFailed: false,
            }

        case SET_ORDER_DATA:
            return {
                ...state,
                loading: false,
                exception: false,
                orderData: action.payload
            }

        case SET_SELECTED_ADDRESS:
            return {
                ...state,
                loading: false,
                exception: false,
                selectedAddress: action.payload
            }

        //set selected address after calculating route
        case SUCCESS_CALCULATE_ADDRESS_ROUTE:
            return {
                ...state,
                loading: false,
                exception: false,
                selectedAddress: action.payload
            }

        case ORDER_PROCESSED:
            return {
                ...state,
                loading: false,
                exception: false,
                processedOrder: {
                    createdOrder: action.payload,
                    orderData: state.orderData
                }

            }

        case ORDER_PAYMENT_FAILED:
            return {
                ...state,
                loading: false,
                exception: false,
                paymentProcessing: false,
                paymentCanceled: false,
                paymentFailed: action.payload
            }

        case ORDER_PLACED:
            return {
                ...state,
                loading: false,
                exception: false,
                paymentProcessing: false,
                paymentCanceled: false,
                paymentFailed: false,
                placedOrder: action.payload
            }

        case ORDER_CENCELED:
            return {
                ...state,
                loading: false,
                exception: false,
                orderCanceled: action.payload
            }

        case CLEAR_DATA_IN_ORDER_PROCESS_REDUCER:
            return {
                ...state,
                loading: false,
                exception: false,
                orderData: null,
                selectedAddress: action.payload.clearSelectedAddress ? false : state.selectedAddress,
                paymentProcessing: false,
                paymentCanceled: false,
                paymentFailed: false,
                placedOrder: false,
                orderCanceled: false
            }

        case CLEAR_PROCESSED_ORDER:
            return {
                ...state,
                loading: false,
                exception: false,
                processedOrder: null
            }

        case EXCEPTION_GENERATED_IN_ORDER_PROCESS_REDUCER:
            return {
                ...state,
                loading: false,
                paymentProcessing: false,
                exception: action.payload,
                orderData: action.payload?.clearOrder ? null : state.orderData
            };

        case CLEAR_EXCEPTION_AND_LOADING_STATE:
            return {
                ...state,
                loading: false,
                exception: false,
            };

        default:
            return state;
    }
}

export default orderProcessReducer;

